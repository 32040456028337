<template>
  <div>
    <template v-if="!loading">
      <!-- 上半部 -->
      <div class="m-2 d-flex fw-bolder">
        <div class="me-2 w-100 row">
          <!-- 選擇年份 -->
          <div class="col-6 p-0">
            <p>選擇年份</p>
            <select
              class="w-100"
              name="SelectYear"
              id="SelectYear"
              v-model="selectYear"
              style="height: 30px"
            >
              <template v-for="year in 2100" :key="year">
                <option :value="year" v-if="year >= 2017">{{ year }}</option>
              </template>
            </select>
          </div>
          <!-- 選擇月份 -->
          <div class="col-6 p-0">
            <p>選擇月份</p>
            <select
              class="w-100"
              name="SelectMonth"
              id="SelectMonth"
              v-model="selectMonth"
              style="height: 30px"
            >
              <template v-for="month in 12" :key="month">
                <option :value="month">
                  {{ month < 10 ? `0${month}` : `${month}` }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <button
          class="tw-btn tw-btn-success"
          style="width: 55px"
          @click="search"
        >
          產生報表
        </button>
      </div>

      <!-- 下半部 -->
      <div v-if="selectDate && showData">
        <div>
          <!-- 過去30天每日訂單成立總金額 -->
          <div class="tw-container border border-1 border-secondary shadow p-0">
            <div class="table mb-0">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th
                      style="
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      "
                    ></th>
                    <th>
                      <span>
                        {{ selectDate.split("-")[1] }}月每日訂單成立總金額({{
                          orderTotalPrice
                        }}元)
                      </span>
                    </th>
                    <th class="text-end">
                      <img
                        class="info-icon"
                        src="@/assets/icon/interrogation.png"
                        @click="introductionModal.show()"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p-1" colspan="3">
                      <div class="card border-0">
                        <Chart
                          type="bar"
                          :data="chartData.stackedData"
                          :options="chartData.stackedOptions"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 左 -->
          <div class="col-md-6 p-0">
            <!-- 銷貨數據 -->
            <div
              class="tw-container border border-1 border-secondary shadow p-0"
            >
              <div class="table-responsive">
                <table class="table table-hover m-0">
                  <thead>
                    <th
                      colspan="4"
                      class="p-2"
                      style="
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      "
                    >
                      銷貨數據
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <template v-for="(data, key) in salesData" :key="key">
                        <td>{{ key }}</td>
                      </template>
                    </tr>
                    <tr>
                      <td>訂單總金額</td>
                      <template v-for="(date, key) in salesData" :key="key">
                        <td>
                          <span v-if="!date.orderTotalPrice.loading">{{
                            date.orderTotalPrice.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>未結單金額</td>
                      <template v-for="(date, key) in salesData" :key="key">
                        <td>
                          <span v-if="!date.noCheckOutPrice.loading">{{
                            date.noCheckOutPrice.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4" style="border-bottom-width: 0px">
                        <p class="text-start mb-2">
                          訂單累計金額：
                          <span v-if="ordersPriceTotal !== null">{{
                            ordersPriceTotal
                          }}</span>
                          <Spinner v-else></Spinner>
                        </p>
                        <p class="text-start mb-2">
                          未結單累計金額：
                          <span v-if="noCheckOutPriceTotal !== null">{{
                            noCheckOutPriceTotal
                          }}</span>
                          <Spinner v-else></Spinner>
                        </p>
                        <p class="text-end tw-text-size12">單位：元</p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <!-- 現金流 -->
            <div
              class="tw-container border border-1 border-secondary shadow p-0"
            >
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <th
                      colspan="4"
                      class="p-2"
                      style="
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      "
                    >
                      現金流
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>{{ key }}</td>
                      </template>
                    </tr>
                    <tr>
                      <td>帳單明細總額</td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>
                          <span v-if="!data.billBodiesPrice.loading">{{
                            data.billBodiesPrice.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>叫貨(費用支出)</td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>
                          <span v-if="!data.cost.loading">{{
                            data.cost.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>叫貨(運費支出)</td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>
                          <span v-if="!data.shippingCost.loading">{{
                            data.shippingCost.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>現貨入庫成本</td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>
                          <span v-if="!data.stockCost.loading">{{
                            data.stockCost.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>總和(利潤%)</td>
                      <template v-for="(data, key) in cashData" :key="key">
                        <td>
                          <span v-if="!data.profit.loading">
                            {{ $methods.numberToFixed(data.profit.value) }}
                            <p class="text-secondary">
                              ({{
                                $methods.numberToFixed(data.profit.percent)
                              }}%)
                            </p>
                          </span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                  <tfoot style="border-top: 0px">
                    <tr>
                      <td
                        class="text-end tw-text-size12"
                        style="border-bottom-width: 0px"
                        colspan="4"
                      >
                        單位：元
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- 右 -->
          <div class="col-md-6 p-0">
            <!-- 數據 -->
            <div
              class="tw-container border border-1 border-secondary shadow p-0"
              style="height: 180px"
            >
              <div class="table-responsive">
                <table class="table table-hover m-0">
                  <thead>
                    <th
                      colspan="4"
                      class="p-2"
                      style="
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      "
                    >
                      數據
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <template v-for="(data, key) in commentData" :key="key">
                        <td>{{ key }}</td>
                      </template>
                    </tr>
                    <tr>
                      <td>新品上架數</td>
                      <template v-for="(data, key) in commentData" :key="key">
                        <td>
                          <span v-if="!data.newUpper.loading">{{
                            data.newUpper.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>FB留言數</td>
                      <template v-for="(data, key) in commentData" :key="key">
                        <td>
                          <span v-if="!data.commentQuantity.loading">{{
                            data.commentQuantity.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>FB留言有成立訂單</td>
                      <template v-for="(data, key) in commentData" :key="key">
                        <td>
                          <span v-if="!data.handledCommentQuantity.loading">{{
                            data.handledCommentQuantity.value
                          }}</span>
                          <Spinner v-else></Spinner>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fw-bolder d-flex justify-content-center align-items-center"
        style="height: 100px"
        v-else
      >
        <span class="alert alert-success p-3"
          >請選擇一個月份，在點擊「搜尋」</span
        >
      </div>
    </template>
    <div
      class="fw-bolder d-flex justify-content-center align-items-center"
      style="height: 100px"
      v-else
    >
      <span class="alert alert-danger p-3">計算中...</span>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/performance.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Chart
import Chart from 'primevue/chart'
// mixin
import { Permission } from '@/methods/mixins/permission';
// spinner
import Spinner from '@/components/tools/Spinner.vue'

export default {
  components: { Chart, Spinner },
  data() {
		return {
      // modal
      introductionModal: {},
      // data
      loading: false,
      serverToken: '',
      storeId: 0,
      selectYear: null,
      selectMonth: null,
      selectDate: null,
      showData: false,
      threeMonth: [],
      merchandiseIds: [],
      // 圖表
      orderTotalPrice: 0,
      chartData: {
        stackedData: {
          labels: [],
          datasets: []
        },
        stackedOptions: {
          scales: {
            x: {
              stacked: true,
              grid: {
                color: ''
              }
            },
            y: {
              stacked: true,
            }
          }
        },
      },  
      // 銷售數據  
      salesData: {},
      noCheckOutPriceTotal : 0,
      ordersPriceTotal: 0,
      // 現金流
      cashData: {},
      // 數據
      commentData: {},
      // 記錄正在執行的 api / fn
      exeAjax: [],
      exeFn: [],
		}
	},
  mixins: [Permission],
  created() {
    this.initialization()
  },
  mounted() {
    this.createModals(['introductionModal'])
  },
  unmounted() {
    this.toInterrupt()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.selectYear = new Date().getFullYear()
      this.selectMonth = new Date().getMonth() + 1
      this.selectDate = `${this.selectYear}-${this.selectMonth < 10 ? `0${this.selectMonth}` : this.selectMonth}`
      const year = this.selectDate.split('-')[0]
      const month = this.selectDate.split('-')[1]
      if (year === undefined || month === undefined) return
      if (parseInt(year) < 2022 || parseInt(month) < 1 || parseInt(month) > 12) return
      this.threeMonth = [this.selectDate]
      // 取得這個月份加上往前推兩個月分
      for (let i = 1; i < 3 ; i++) {
        const nextYear = month - i > 0 ? year : year - 1
        const nextMonth = month - i > 0 ? month - i : month - i + 12
        this.threeMonth.push(`${nextYear}-${nextMonth < 10 ? '0' : ''}${nextMonth}`)
      }
      this.chartProcessing('initialization')
      this.salesDataProcessing('initialization-false') // 後面的 boolean 是 loading 的 boolean
      this.cashProcessing('initialization-false')
      this.commentDataProcessing('initialization-false')
    },
    // 搜尋
    search:_.debounce(function() {
      if (this.currentParticipantPermission && !this.currentParticipantPermission.accessStoreReport) return this.SweetAlert('other', '您尚未有此權限')
      console.log('------------------ 開始取業績 --------------------')
      // 檢查是否資料載入完成
      const canNotNextStep = this.checkIsPosiibleToSearch()
      if (canNotNextStep) return this.SweetAlert('other', '資料尚未載入完成')
      if (!this.selectYear) return this.SweetAlert('other', '請選擇一個年份')
      if (!this.selectMonth) return this.SweetAlert('other', '請選擇一個月份')
      this.selectDate = `${this.selectYear}-${this.selectMonth < 10 ? `0${this.selectMonth}` : this.selectMonth}`
      this.showData = false
      this.loading = true
      this.$methods.switchLoading('show')
      const year = this.selectDate.split('-')[0]
      const month = this.selectDate.split('-')[1]
      if (year === undefined || month === undefined) return
      if (parseInt(year) < 2022 || parseInt(month) < 1 || parseInt(month) > 12) return
      this.threeMonth = [this.selectDate]
      // 取得這個月份加上往前推兩個月分
      for (let i = 1; i < 3 ; i++) {
        const nextYear = month - i > 0 ? year : year - 1
        const nextMonth = month - i > 0 ? month - i : month - i + 12
        this.threeMonth.push(`${nextYear}-${nextMonth < 10 ? '0' : ''}${nextMonth}`)
      }
      // 初始化所有表格資料
      this.chartProcessing('initialization')
      this.salesDataProcessing('initialization-true')
      this.cashProcessing('initialization-true')
      this.commentDataProcessing('initialization-true')
      setTimeout(() => {
        // 迴圈取資料
        this.threeMonth.forEach(item => {
          const i_year = item.split('-')[0]
          const i_month = item.split('-')[1]
          const startTime = new Date(i_year, i_month - 1) * 1 / 1000
          const endTime = new Date(i_year, i_month, 0, '23', '59', '59') * 1 / 1000
          this.getPerformance(startTime, endTime, '26', item)
          this.getPerformance(startTime, endTime, '27', item)
          this.getPerformance(startTime, endTime, '15', item)
          this.getPerformance(startTime, endTime, '16', item)
        })
        this.showData = true
        console.log(this.showData)
        this.loading = false
        this.$methods.switchLoading('hide')
      }, 500)
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 取得業績資料
    getPerformance(startTime, endTime, type, currentDate) {
      let data = []
      switch (type) {
        case '16':
          data = [{
            query: {"columns":[{"columnName":"id","siteObjectType":16}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"createTime","siteObjectType":16},"operator":{"operator":">=","type":1,"value":{"name":`from_unixtime(${startTime})`}}},{"column":{"columnName":"createTime","siteObjectType":16},"operator":{"operator":"<=","type":1,"value":{"name":`from_unixtime(${endTime})`}}}],"operator":1},"orders":[{"asc":true,"column":{"columnName":"id","siteObjectType":16}}],"siteObjectType":16,"sqlCommandType":1},
            methods: '{getAllUserFbCommentLinks,getStocks{getMerchandiseStyles,getAllStockHistories}}'
          }]
          break;
        case '26':
          data = [{
            query: {"columns":[{"columnName":"id","siteObjectType":26}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"createTime","siteObjectType":26},"operator":{"operator":">=","type":1,"value":{"name":`from_unixtime(${startTime})`}}},{"column":{"columnName":"createTime","siteObjectType":26},"operator":{"operator":"<=","type":1,"value":{"name":`from_unixtime(${endTime})`}}}],"operator":1},"orders":[{"asc":true,"column":{"columnName":"id","siteObjectType":26}}],"siteObjectType":26,"sqlCommandType":1},
            methods: '{getCheckOutQuantity}'
          }]
          break;
        case '27':
          data = [{
            query: {"columns":[{"columnName":"id","siteObjectType":27}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":27},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"createTime","siteObjectType":27},"operator":{"operator":">=","type":1,"value":{"name":`from_unixtime(${startTime})`}}},{"column":{"columnName":"createTime","siteObjectType":27},"operator":{"operator":"<=","type":1,"value":{"name":`from_unixtime(${endTime})`}}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"id","siteObjectType":27}}],"siteObjectType":27,"sqlCommandType":1},
            methods: '{getTotalPrice,getBodyPrice}'
          }]
          break;
        case '15':
          data = [{
            query: {"columns":[{"columnName":"id","siteObjectType":15}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":15},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"createTime","siteObjectType":15},"operator":{"operator":">=","type":1,"value":{"name":`from_unixtime(${startTime})`}}},{"column":{"columnName":"createTime","siteObjectType":15},"operator":{"operator":"<=","type":1,"value":{"name":`from_unixtime(${endTime})`}}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"id","siteObjectType":15}}],"siteObjectType":15,"sqlCommandType":1},
            methods: '{getBodyCost}'
          }]
          break;
        default:
          throw new Error('Performance.vue > getPerformance > type 設定錯誤')
      }
      const vm = this
      const getPerformanceApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const ajax = $.ajax({
        type: 'POST',
        async: true,
        url: getPerformanceApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const data = res.data[0].objects
            vm.sortoutPerformance(data, type, currentDate)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON) vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
      this.exeAjax.push(ajax)
    },
    // 業績資料整理
    sortoutPerformance(performanceData, type, currentDate) {
      if (type === '26') {
        const timeoutPoint = setTimeout(() => {
          console.log('訂單跑迴圈')
          // 用來檢查是否紀錄過日期
          let fullDateMap = new Map()
          // 用來記錄 "數據 > FB流言有成立訂單" 用的
          let userFbCommentLinkMap = new Map()
          // 訂單迴圈
          performanceData.forEach(item => {
            // 處理圖表
            if (currentDate && currentDate === this.selectDate) {
              this.chartProcessing('dataProcessing', item, fullDateMap)
              this.orderTotalPrice = this.$methods.numberToFixed(this.orderTotalPrice)
            }
            // 處理銷售數據
            this.salesDataProcessing('dataProcessing', item, userFbCommentLinkMap)
          })
          this.salesData[currentDate].orderTotalPrice.loading = false
          this.salesData[currentDate].noCheckOutPrice.loading = false
          this.commentData[currentDate].handledCommentQuantity.loading = false
        }, 100)
        this.exeFn.push(timeoutPoint)
      }
      if (type === '16') {
        const timeoutPoint = setTimeout(() => {
          console.log('商品跑迴圈')
          // 跑商品迴圈
          performanceData.forEach(item => {
            this.commentDataProcessing('dataProcessing', item)
            // 處理現貨入庫成本
            this.sortoutStockHistories(item)
          })
          this.commentData[currentDate].newUpper.loading = false
          this.commentData[currentDate].commentQuantity.loading = false
          this.cashData[currentDate].stockCost.loading = false
          this.countProfit()
        }, 100)
        this.exeFn.push(timeoutPoint)
      }
      if (type === '27') {
        console.log('帳單跑迴圈')
        const timeoutPoint = setTimeout(() => {
          // 帳單跑迴圈
          performanceData.forEach(item => {
            this.cashProcessing('dataProcessing', item)
          })
          this.countProfit()
        }, 100)
        this.exeFn.push(timeoutPoint)
      }
      if (type === '15') {
        const timeoutPoint = setTimeout(() => {
          console.log('叫貨單跑迴圈')
          // 叫貨單跑迴圈
          performanceData.forEach(item => {
            this.cashProcessing('dataProcessing', item)
          })
          console.log(currentDate)
          this.cashData[currentDate].billBodiesPrice.loading = false
          this.cashData[currentDate].cost.loading = false
          this.cashData[currentDate].shippingCost.loading = false
          this.countProfit()
        }, 100)
        this.exeFn.push(timeoutPoint)
      }
    },
    // * 圖表處理
    chartProcessing(states, merchOrder, fullDateMap) {
      // 圖表初始化
      if (states === 'initialization') {
        this.chartData.stackedData = {
          labels: [],
          datasets: []
        }
        const charClass = [['代客加單','#7B7B7B'],['個人賣場','#FFD306'],['Facebook','#0066CC'],['LINE','#00DB00']]
        charClass.forEach(item => {
          let obj = {
            type: 'bar',
            label: item[0],
            backgroundColor: item[1],
            data: []
          }
          this.chartData.stackedData.datasets.push(obj)
        })
        this.orderTotalPrice = 0
      }
      // 圖表資料整理
      if (states === 'dataProcessing') {
        this.orderTotalPrice += (merchOrder.price * merchOrder.quantity)
        const createTime = merchOrder.createTime
        const year = new Date(createTime).getFullYear()
        const month = new Date(createTime).getMonth() + 1
        const date = new Date(createTime).getDate()
        const fullDate = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`
        let datasetsIndex = 0
        switch (merchOrder.creationType) {
          case 'SELLERCREATE':
            datasetsIndex = 0
            break;
          case 'CUSTOMERCREATE':
            datasetsIndex = 1
            break;
          case 'CUSTOMERFROMFBCOMMENT':
            datasetsIndex = 2
            break;
          case 'SELLERFROMFBCOMMENT':
            datasetsIndex = 2
            break;
          case 'CUSTOMERFROMLINEGROUP':
            datasetsIndex = 3
            break;
        }
        if (!fullDateMap.has(fullDate)) {
          fullDateMap.set(fullDate)
          this.chartData.stackedData.labels.push(fullDate)
        }
        let labelsIndex = null
        this.chartData.stackedData.labels.some((date, index) => {
          if (date === fullDate) {
            labelsIndex = index
            return true
          }
        })
        if (!this.chartData.stackedData.datasets[datasetsIndex].data[labelsIndex]) this.chartData.stackedData.datasets[datasetsIndex].data[labelsIndex] = 0
        this.chartData.stackedData.datasets[datasetsIndex].data[labelsIndex] += merchOrder.quantity * merchOrder.price
        this.chartData.stackedData.datasets[datasetsIndex].data[labelsIndex] = this.$methods.numberToFixed(this.chartData.stackedData.datasets[datasetsIndex].data[labelsIndex])
      }
    },
    // * 銷售數據 / 數據內 FB 留言有成立訂單
    salesDataProcessing(states, merchOrder, userFbCommentLinkMap) {
      //資料初始化
      if (states.match(/initialization/gm)) {
        this.salesData = {}
        this.noCheckOutPriceTotal = null
        this.ordersPriceTotal = null
        this.threeMonth.forEach(month => {
          this.salesData[month] = {
            orderTotalPrice: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            noCheckOutPrice: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
          }
        })
      }
      // 資料處理
      if (states === 'dataProcessing') {
        const createTime = merchOrder.createTime
        const year = new Date(createTime).getFullYear()
        const month = new Date(createTime).getMonth() + 1
        const fullDate = `${year}-${month < 10 ? `0${month}` : month}`
        this.salesData[fullDate].orderTotalPrice.value += (merchOrder.price * merchOrder.quantity)
        this.salesData[fullDate].noCheckOutPrice.value += ((merchOrder.quantity - merchOrder.checkOutQuantity) * merchOrder.price)
        this.noCheckOutPriceTotal += ((merchOrder.quantity - merchOrder.checkOutQuantity) * merchOrder.price)
        this.ordersPriceTotal += merchOrder.price * merchOrder.quantity
        this.salesData[fullDate].orderTotalPrice.value = this.$methods.numberToFixed(this.salesData[fullDate].orderTotalPrice.value)
        this.salesData[fullDate].noCheckOutPrice.value = this.$methods.numberToFixed(this.salesData[fullDate].noCheckOutPrice.value)
        this.noCheckOutPriceTotal = this.$methods.numberToFixed(this.noCheckOutPriceTotal)
        this.ordersPriceTotal = this.$methods.numberToFixed(this.ordersPriceTotal)
        // 數據內 FB 留言有成立訂單
        if ((merchOrder.creationType === 'CUSTOMERFROMFBCOMMENT' || merchOrder.creationType === 'SELLERFROMFBCOMMENT') && merchOrder.sourceType === 'USERFBCOMMENTLINK') {
          if (!userFbCommentLinkMap.has(merchOrder.sourceId)) userFbCommentLinkMap.set(merchOrder.sourceId)
          if (userFbCommentLinkMap.has(merchOrder.sourceId)) this.commentData[fullDate].handledCommentQuantity.value ++
        }
      }
    },
    // * 現金流
    cashProcessing(states, performanceData) {
      if (states.match(/initialization/gm)) {
        this.cashData = {}
        this.threeMonth.forEach(month => {
          this.cashData[month] = {
            billBodiesPrice: {
              loading: states === 'initialization-true' ? true : false,
              value: 0,
            },
            cost: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            shippingCost: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            stockCost: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            profit: {
              loading: states === 'initialization-true' ? true : false,
              value: 0,
              percent: 0,
            },
          }
        })
      }
      if (states === 'dataProcessing') {
        // 處理帳單
        if (performanceData.class.match(/BillHeader/gm)) {
          const createTime = performanceData.createTime
          const year = new Date(createTime).getFullYear()
          const month = new Date(createTime).getMonth() + 1
          const fullDate = `${year}-${month < 10 ? `0${month}` : month}`
          if(!performanceData.parentId) {
            this.cashData[fullDate].billBodiesPrice.value += performanceData.bodyPrice
            if (performanceData.walletUsed) this.cashData[fullDate].billBodiesPrice.value -= performanceData.walletUseAmount
          }
          this.cashData[fullDate].billBodiesPrice.value = this.$methods.numberToFixed(this.cashData[fullDate].billBodiesPrice.value)
        }
        // 處理叫貨單
        if (performanceData.class.match(/PurchaseOrderHeader/gm)) {
          const createTime = performanceData.createTime
          const year = new Date(createTime).getFullYear()
          const month = new Date(createTime).getMonth() + 1
          const fullDate = `${year}-${month < 10 ? `0${month}` : month}`
          this.cashData[fullDate].cost.value += performanceData.bodyCost
          this.cashData[fullDate].shippingCost.value += performanceData.shippingCost
          this.cashData[fullDate].cost.value = this.$methods.numberToFixed(this.cashData[fullDate].cost.value)
          this.cashData[fullDate].shippingCost.value = this.$methods.numberToFixed(this.cashData[fullDate].shippingCost.value)
        }
      }
    },
    // * 數據
    commentDataProcessing(states, merchandise) {
      if (states.match(/initialization/gm)) {
        this.commentData = {}
        this.threeMonth.forEach(month => {
          this.commentData[month] = {
            newUpper: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            commentQuantity: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            },
            handledCommentQuantity: {
              loading: states === 'initialization-true' ? true : false,
              value: 0
            }
          }
        })
      }
      if (states === 'dataProcessing') {
        const createTime = merchandise.createTime
        const year = new Date(createTime).getFullYear()
        const month = new Date(createTime).getMonth() + 1
        const fullDate = `${year}-${month < 10 ? `0${month}` : month}`
        // this.merchandiseIds.push(merchandise.id)
        if (!merchandise.deleted) this.commentData[fullDate].newUpper.value ++
        this.commentData[fullDate].commentQuantity.value += merchandise.allUserFbCommentLinks.length
      }
    },
    // 整理庫存歷史紀錄
    sortoutStockHistories(merchandise) {
      merchandise.stocks.forEach(stock => {
        stock.allStockHistories.forEach(stockHistory => {
          const createTime = stockHistory.createTime
          const year = new Date(createTime).getFullYear()
          const month = new Date(createTime).getMonth() + 1
          const fullDate = `${year}-${month < 10 ? `0${month}` : month}`
          const cost = stock.merchandiseStyles[0].cost
          const quantity = stockHistory.quantity
          if (stockHistory.sourceType === 'STOCK' && this.cashData[fullDate]) {
            console.log(fullDate)
            this.cashData[fullDate].stockCost.value += this.$methods.numberToFixed(cost * quantity)
            console.log(`date: ${fullDate}, cost: ${cost}, quantity: ${quantity}, total: ${this.cashData[fullDate].stockCost.value}`)
          }
        })
      })
    },
    // 計算現金流利潤
    countProfit() {
      for (const [key, date] of Object.entries(this.cashData)) {
        const billBodiesPrice = date.billBodiesPrice.value
        const cost = (date.cost.value + date.shippingCost.value + date.stockCost.value)
        date.profit.value = billBodiesPrice - cost
        date.profit.percent = cost !== 0 ? parseInt(((billBodiesPrice - cost) / cost) * 100) : 100
        if (billBodiesPrice === 0 && cost === 0) date.profit.percent = 0
        date.profit.loading = false
      }
    },
    // 中斷
    toInterrupt() {
      this.exeAjax.forEach(ajax => {
        ajax.abort()
      })
      this.$methods.breakOff(this.exeFn)
      this.exeAjax = []
      this.exeFn = []
    },
    // 檢查是否資料載入完成 (因為中斷有時無法成功，導致連續點兩次搜尋會有可能資料疊加)
    checkIsPosiibleToSearch() {
      const canNotNextStep = this.threeMonth.some(month => {
        return this.salesData[month].orderTotalPrice.loading || this.salesData[month].noCheckOutPrice.loading ||
          this.cashData[month].billBodiesPrice.loading || this.cashData[month].cost.loading || this.cashData[month].shippingCost.loading ||
          this.cashData[month].stockCost.loading || this.cashData[month].profit.loading || this.commentData[month].newUpper.loading ||
          this.commentData[month].commentQuantity.loading || this.commentData[month].handledCommentQuantity.loading
      })
      return canNotNextStep
    }
  },
}
</script>